.contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: rem(40);
    row-gap: rem(30);
    width: 73%;
    margin: 0 auto;
    padding-top: rem(46);
    padding-bottom: rem(46);

    &__form-elem {
        flex: 1 auto;

        &--full {
            flex: 1 100%;
        }
    }

    &__form-file {
        cursor: pointer;
        position: relative;
        display: block;
        padding: rem(16) rem(20) rem(15);
        border: 1px solid $text;
        margin-top: rem(-7);

        input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: white;
            display: block;
            cursor: pointer;
            font-size: 0;
        }

        .file-information {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
        }
    
        .file-title {
            font-size: rem(22);
            line-height: rem(30);
            color: $text;
        }

        .file-text {
            font-size: rem(15);
            line-height: rem(20);
            color: #7D7D7D;
        }
    }

    &__recaptcha {
        flex: 1 100%;
        display: flex;
        justify-content: end;
    }

    &__personal-wrap {
        display: flex;
        width: 38%;
        font-size: rem(21);
        line-height: 120%;

        a {
            font-size: rem(21);
            line-height: 120%;
        }

        input {
            margin-right: rem(16);
            margin-top: rem(4);
            width: rem(19);
            min-width: rem(19);
            height: rem(19);
            min-height: rem(19);
        }
    }

    &__btn-wrap {
        flex: 0 48%;
        .btn {
            width: 100%;
        }
    }

    &__personal {
        margin-top: rem(14);
    }
}

@media (max-width: 1279px) {
    .contact-form {
        padding-top: mrem(35);
    }

    .contact-text-wrap {
        margin-bottom: mrem(35);
    }
}

@media (max-width: 992px) {
    .contact-form {
        width: 100%;
    }

    .contact-text-wrap {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}

@media (max-width: 767px) {
    .contact-form {
        &__form-elem {
            flex: 1 100%;
        }

        &__personal-wrap {
            width: 100%;
        }

        &__btn-wrap {
            flex: 0 100%;
        }
    }
}
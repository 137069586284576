$main: #1E372A;
$orange: #EBA049;
$beige: #F3F2ED;
$gold: #EFC99B;
$white: #FCFCFC;
$red: #D62B2B;
$green:#1E372A;
$dark-green: #122223;

$text: #122223;
$tr-time: .4s;
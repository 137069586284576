.section-about {
	padding: rem(115) 0;
	overflow-x: clip;

	.section-about {
		&__slider {
			&-goals-grid {
				overflow: visible;
			}
		}
	}

	&__text-container {
		display: flex;
		padding: 0 0 rem(80) rem(226);
	}

	&__title {
		flex: 0 0 auto;
		font-size: rem(56);
		letter-spacing: 0.08em;
		text-transform: uppercase;
	}

	&__text {
		flex: 0 0 43.9%;
		margin-left: rem(194);
	}

	&__link {
		display: inline-block;
		margin-top: rem(58);
		border-bottom: 1px solid $text;
		font-weight: 400;
		font-size: rem(26);
		line-height: rem(30);
		transition: $tr-time;

		@media (min-width: 1024px) {
			&:hover {
				color: $orange;
				border-color: $orange;
			}
		}
	}

	&__content {
		position: relative;

		&::before,
		&::after {
			content: "";
			background: $text;
			position: absolute;
			z-index: -1;
			right: 0;
		}

		&::before {
			top: 0;
			width: 20%;
			height: 34.8%;
		}

		&::after {
			top: 34.8%;
			width: 100%;
			// height: 61%;
			height: rem(845);
		}
	}

	&__cork-img {
		width: 76.5%;
		height: rem(889);
		margin: 0 auto;
	}

	&__goal {
		// min-height: rem(290);
		padding: rem(87) rem(70);
		color: $gold;
		background: $main;
		transition: background-color $tr-time;

		// &:not(:nth-child(3n+2)) {
		// 	transform: translateY(rem(50));
		// }

		&-title {
			margin-bottom: rem(20);
			font-size: rem(30);
			line-height: 1.35;
			letter-spacing: 0.12em;
			text-transform: uppercase;
			text-align: center;
		}

		&-text {
			font-size: rem(19);
			line-height: 1.1;
			text-align: center;
		}

		&-imgbtn {
			padding-right: rem(43);
			padding-left: rem(20);
			font-weight: 400;
			font-size: rem(24);
			line-height: rem(33);
			display: flex;
			border: 1px solid #efc99b;
			background: transparent;
			align-items: center;
			justify-content: center;
		}

		.hover_text {
			padding: 20px;
			width: 100%;
			height: 100%;
			display: block;
			font-size: calcFluid(10, 16, 1400, 1920);

			@media (max-width: 1200px) {
				font-size: 12px;
			}
		}

		&-slideEndBtn {
			padding-right: rem(43);
			padding-left: rem(20);
			font-weight: 400;
			font-size: rem(24);
			line-height: rem(33);
			display: flex;
			border: 1px solid white;
			background: transparent;
			align-items: center;
			justify-content: center;
			margin-top: 10px;
		}

		&-slideEndBtn {
			&:hover {
				border: 1px solid #eba049;
				background: #eba049;
			}
		}

		&-activebtn {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #1a4444;
			transition: all 0.3s ease;
		}

		&-btntext {
			color: #efc99b;
			font-size: rem(24);
			line-height: 1.1;
			text-align: center;
		}

		&-btntextEnd {
			color: white;
			font-size: rem(24);
			line-height: 1.1;
			text-align: center;
		}

		&-slide {
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		&:hover {
			background: #1a4444;
			cursor: pointer;
			height: auto;
		}

		// &:hover &-activebtn {
		// 	opacity: 1;
		// }
	}

	&__goals {
		.section-about__slider-goals-grid {
			overflow: visible;
		}

		// max-width: calc(76.46vw + 42px);
		padding-top: rem(70);

		&-title {
			margin-bottom: rem(60);
			font-size: rem(56);
			line-height: 1.35;
			color: $gold;
			letter-spacing: 0.12em;
			text-transform: uppercase;
			text-align: center;
		}

		&-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: rem(20);
		}
	}

	&__isocork {
		font-size: rem(135);
		line-height: 1;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		position: absolute;
		color: #efc99b;
		writing-mode: vertical-rl;
		transform: rotate(-180deg);

		&--first {
			top: -20%;
			right: rem(-47);
		}

		&--last {
			bottom: -11%;
			left: rem(-58);
		}
	}
}

@media (max-width: 1279px) {
	.section-about {
		padding: mrem(35) 0;

		&__text-container {
			padding-left: mrem(21);
		}

		&__title {
			font-size: mrem(26);
			line-height: 1;
		}

		&__isocork {
			display: none;
		}

		&__cork-img {
			width: 100%;
			height: auto;
		}

		&__text {
			flex: 1;
		}

		&__content::before {
			display: none;
		}

		&__goals {
			max-width: 100%;
			padding-top: mrem(35);

			&-title {
				font-size: mrem(26);
				line-height: 1.2;
				margin-bottom: mrem(35);
			}
		}

		&__goal {
			padding: mrem(35) mrem(21) mrem(29);
			min-height: auto;

			&-title {
				font-size: mrem(18);
				margin-bottom: mrem(15);
			}
		}
	}
}

@media (max-width: 992px) {
	.section-about {
		&__text-container {
			flex-direction: column;
			padding: 0 mrem(21) mrem(44);
		}

		&__text {
			margin-left: 0;
			padding: mrem(25) 0 0;
			text-align: center;
		}

		&__title {
			text-align: center;
		}

		&__goals {
			&-grid {
				grid-template-columns: 1fr;
				gap: 0;
				margin: 0 mrem(-21);
			}
		}

		&__goal {
			&:not(:nth-child(3n + 2)) {
				transform: inherit;
			}
		}

		&__content::after {
			top: 0;
			height: 100%;
		}
	}
}

.hovertext {
	&:hover {
		.section-about__goal-imgscr {
			transform: translate(calcFluid(-138, -195, 1200, 1920), calcFluid(-52, -50, 1200, 1920));

			@media (min-width: 1921px) {
				transform: translate(-280px, -90px);
			}

			transition: all 1s ease;

			img {
				width: 35px;
				height: 35px;
			}
		}

		.section-about__goal-text {
			opacity: 0;
		}

		.section-about__goal-activebtn {
			opacity: 1;
			background: transparent;

			.hover_text {
				padding-top: mrem(60);
			}
		}
	}
}

.section-gallery {
    padding: rem(115) 0;
}

.gallery {
    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: rem(30);
    }

    &__item {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        padding-bottom: 100%;

        img {
            display: block;
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

.section-text-content + .section-gallery {
    padding-top: 0;
}

@media (max-width: 767px) {
    .gallery {
        &__grid {
            grid-template-columns: repeat(2, 1fr);
            gap: rem(20);
        }
    }
}

@media (max-width: 1279px) {
    .section-gallery{ 
        padding: mrem(35) 0;
    }
}
:root{
	font-size: calcFluid(16,24,1280,1920);
	scroll-behavior: smooth;
}
@media (min-width: 1920px) {
	:root{
		font-size: 24px;
	}
}
[x-cloak] { display: none !important; }
body{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
	color: $text;
	background: $beige;
	font-family: 'Open Sans', sans-serif;
	word-break: break-word;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
main{
	position: relative;
	flex-grow: 1;
}
*{
	margin: 0;
	padding: 0;
}
ol,ul{
	list-style: none;
}
button{
	border: 0;
	font-family: inherit;
}
button, input, textarea{
	font-family: inherit;
}
input{
	min-width: 0;
	font-size: inherit;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}
a{
	color: inherit;
	text-decoration: none;
	transition: color $tr-time;
	&:hover{
		color: $orange;
		text-decoration: none;
	}
}
svg{
	vertical-align: middle;
	transition: fill $tr-time;
}
img, iframe, svg, picture{
	display: block;
}
iframe{
	border: 0;
}
h1, h2, h3{
	font-weight: 400;
}
*, ::before, ::after{
	box-sizing: border-box;
}
:focus{
	outline: none;
}

@media (max-width: 1279px) {
	:root{
		font-size: calcFluid(16,24,320,1279);
	}
}
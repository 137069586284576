.section-equipment {
    padding: rem(160) 0 rem(115);
    overflow-x: clip;

    &__wrap {
        background: $text;
        padding: rem(140) 0 rem(128);
    }

    .section-title {
        color: $gold;
        font-size: rem(80);
        line-height: 100%;
        text-align: center;
    }

    &__subtitle {
        margin-top: rem(45);
        font-size: rem(36);
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        color: $gold;
        width: 100%;
    }

    &__grid {
        margin-top: rem(90);
    }

    .keen-slider:not([data-keen-slider-disabled]) {
        overflow: visible;
    }

    &__item {
        aspect-ratio: 0.97;
        cursor: pointer;
        position: relative;
        min-height: auto !important;
        
        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:hover{
            .section-equipment__item-text{
                opacity: 1;
            }
        }

        &-text {
            position: absolute;
            bottom: 0;
            opacity: 0;
            transition:  all 1s ease;
            min-height: rem(130);
            width: 100%;
            background: $main;
            padding: rem(20) rem(29);

            font-size: rem(25);
            line-height: 120%;
            text-align: center;
            color: #FFFFFF;

            display: flex;
            align-items: center;
            justify-content: center;

        }
    }

    &__arrows {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem(80);
        --icon-width: #{rem(128)};
		--icon-height: #{rem(25)};
		--icon-color: #{$gold};

		.slider-arrow{
			&--right{
				margin: 0 0 0 rem(91);
			}
		}
    }
}

@media (max-width: 1279px) {
    .section-equipment {      
        padding: mrem(35) 0;

        &__wrap {
            padding: mrem(42) 0;
        }

        .section-title {
            font-size: mrem(26);
        }

        &__subtitle {
            font-size: mrem(18);
            margin-top: mrem(15);
        }

        &__grid {
            margin-top: mrem(50);
        }

        &__arrows {
            margin-top: mrem(50);
    
            .slider-arrow{
                &--right{
                    margin: 0 0 0 mrem(42);
                }
            }
        }
    }
}
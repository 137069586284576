.section-number {
    padding: rem(115) 0;
    overflow: hidden;

    &__wrap {
        display: grid;
        grid-template-columns: 46% 1fr;
        column-gap: rem(94);
    }

    &__left {
        width: 100%;
    }

    &__right {
        width: 100%;
    }

    &__title {
        font-weight: 600;
        font-size: rem(42);
        line-height: rem(57);
        color: $text;
        margin-bottom: rem(60);
    }

    &__text {
        width: 89%;
        margin-bottom: rem(40);
    }

    &__btn {
        width: max-content;
        padding: 0 rem(52);
    }

    &__number {
        &-wrap {
            display: flex;
            justify-content: end;
            flex-wrap: wrap;
            margin-bottom: rem(60);
        }

        &-title {
            font-weight: 600;
            font-size: rem(80);
            line-height: rem(67);
            color: $text;
            margin-bottom: rem(30);
            display: block;
        }

        &-text {
            font-weight: 300;
            font-size: rem(25);
            line-height: 148%;
            color: $text;
        }

        &-item {
            width: 30%;
            margin-right: rem(82);
        }
    }

    &__image {
        width: 100%;
        position: relative;
        padding-bottom: 59%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width: 1279px) {
    .section-number {
        padding: mrem(35) 0;

        &__title {
            font-size: mrem(26);
        }

        &__wrap {
            // grid-template-columns: 1fr;
            // row-gap: mrem(50);
            display: block;
        }

        &__left {
            width: calc(100% - 21px);
            padding-right: 21px;
            margin-bottom: mrem(50);
        }
    }
}

@media (max-width: 992px) {
    .section-number {
        &__image {
            width: calc(100vw + 21px);
            margin-left: -21px;
        }

        &__text {
            width: 100%;
        }
    }
}

@media (max-width: 567px) {
    .section-number {
        &__number-wrap {
            flex-direction: column;
        }

        &__number-item {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: mrem(25); 
            }
        }
        
    }
}
.section-result-for-12years {

  // padding-top: rem(80);
  // padding-right: rem(204);
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0 0px;

  }

  .years {
    width: 60%;
    @media (max-width: 1280px) {
      width: 100%;
    }
    position: relative;
    .years-12-img{
      width: 100%;
    }
    .text{
      color: white;
      font-size:calcFluid(23,62,320,1920);
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      height: calcFluid(50,163,320,1920);
      width: calcFluid(260,734,320,1920);
      position: absolute;
      bottom: calcFluid(38,100,320,1920);
      left: 0;
      display: flex;
      align-items: center;
      background: #122223;
      padding-left: calcFluid(15,75,320,1920);
   
      clip-path: polygon(0 0, 100% 0%, 88% 100%, 0% 100%);
    }
  }

  .text-col {
    align-items: self-start;
    padding-left: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;


  }

  .text-content {
    margin-bottom: rem(95);
    font-family: Inter;
    font-size: rem(25);
    font-weight: 300;
    line-height: rem(37);
    letter-spacing: 0em;
    text-align: left;

    p {
      font-family: Inter;
      font-size: rem(19);
      font-weight: 300;
      line-height: rem(28);
      letter-spacing: 0em;
      text-align: left;

      span {
        font-size: rem(67);
        font-weight: 600;
        line-height: rem(95);
        letter-spacing: 0em;
        text-align: left;
        color: #EBA149;
      }

      strong {
        font-family: Inter;
        text-transform: uppercase;
        font-size: rem(19);
        font-weight: 500;
        line-height: rem(28);
        letter-spacing: 0em;
        text-align: left;
      }
    }

  }


}

@media (max-width: 1280px) {
  .section-result-for-12years {
    .years-12-img {
      width: 100%;
    }

    .container {
      flex-direction: column;
    }

    .text-col {
      margin-top: rem(30);
      width: 100%;

    }

  }
}
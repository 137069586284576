.section-advantages-type2 {
    padding: rem(45) 0 rem(115);

    &__wrap {
        background: $text;
        padding-top: rem(90);
        padding-bottom: rem(120);
    }

    &__title {
        color: $gold;
        margin-bottom: rem(161);
    }

    &__slider {
        &-grid {
            .keen-slider {
                overflow: visible !important;
                overflow-x: clip !important;
            }
        }

        &-arrows {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: rem(75);
            --icon-width: #{rem(130)};
            --icon-height: #{rem(25)};
            --icon-color: #FFFFFF;
            --disabled-icon-color: rgba(255, 255, 255, 0.5);

            @media (max-width: 1279px) {
                margin-top: mrem(50);
            }
    
            .slider-arrow{
                &--right{
                    margin: 0 0 0 rem(91);
    
                    @media (max-width: 1279px) {
                        margin: 0 0 0 mrem(42);
                    }
                }

                @media (min-width: 1024px) {
                    &:not(.slider-arrow--disabled):hover {
                        svg {
                            fill: $gold;
                        }
                    }
                }
            }
        }
    }

    &__item {
        border: 4px solid #FFFFFF;
        border-radius: 5px;
        padding: 0 rem(50) rem(35);
        overflow: visible !important;
        display: flex;
        flex-direction: column;

        &-title {
            font-weight: 500;
            font-size: rem(25);
            line-height: 148%;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: rem(10);
        }

        &-text {
            font-weight: 300;
            font-size: rem(18);
            line-height: 148%;
            text-align: center;
            color: #FFFFFF;
        }

        &-image-wrap {
            width: rem(178);
            height: rem(178);
            border: 6px solid #EFC99B;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: rem(-71) auto rem(20);
            background: $text;

            img {
                width: rem(70);
                height: rem(70);
                object-fit: contain;
            }
        }

        &-btn {
            font-size: rem(20);
            line-height: rem(27);
            padding: rem(18) rem(58);
            margin: rem(36) auto 0;
            width: fit-content;
        }

        &-content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            flex: 1 auto; 
        }
    }
}

@media (max-width: 1279px) {
    .section-advantages-type2  {
        &__title {
            margin-bottom: mrem(75);
        }

        &__item {
            padding-left: mrem(21);
            padding-right: mrem(21);
        }

        &__wrap {
            padding-top: mrem(42);
            padding-bottom: mrem(44);
        }
    }
}
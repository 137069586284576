.section-form{
	padding: rem(160) rem(137) rem(113) 0;
	position: relative;
	background: $text;
	&::after{
		content: '';
		width: 93.7%;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		background: $gold;
	}
	&__container {
		overflow: hidden;
		&:not(:last-child) {
			padding: rem(115) 0;

			.section-form::after {
				display: none;
			}
		}
		
		&:last-child {
			padding: rem(115) 0 0;
		}
	}
	&__title{
		margin-bottom: rem(33);
		font-size: rem(100);
		line-height: 1;
		color: $gold;
		text-transform: uppercase;
	}
	&__form-col{
		width: 42.2%;
		margin-left: auto;
		position: relative;
		z-index: 1;
	}
	&__form-wrap{
		padding-left: rem(216);
	}
	&__text{
		margin-bottom: rem(54);
		font-size: rem(25);
		line-height: 1.42;
		color: #fff;
	}
	&__form-elem{
		margin-bottom: rem(30);
	}
	&__personal{
		margin-top: rem(30);
	}
	&__img{
		position: absolute;
		top: rem(160);
		left: 0;
		width: 57.8%;
		max-height: 66.7%;
		object-fit: cover;
	}

	&__btn {
		margin-bottom: rem(30);
		padding-right: rem(55);
	}
}

@media (max-width: 1279px){
	.section-form{
		padding-right: mrem(60);
		
		&__container {
			&:not(:last-child) {
				padding: mrem(35) 0;
			}
		}
		&__personal{
			margin-top: mrem(15);
			text-align: center;
		}
		&__title{
			margin-bottom: mrem(25);
			font-size: mrem(36);
		}
		&__text{
			margin-bottom: mrem(35);
			font-size: mrem(13);
		}
		&__form-elem{
			margin-bottom: mrem(15);
		}
		&__form-wrap{
			padding-left: mrem(80);
		}
		&__form-col{
			width: 49%;
		}
	}
}

@media (max-width: 991px){
	.section-form{
		padding: mrem(237) 15px mrem(68);
		&::after{
			width: 100%;
		}
		&__btn{
			width: 100%;
		}
		&__form-col{
			width: 100%;
		}
		&__form-wrap{
			padding: 0;
		}
		&__personal{
			padding: 0 12%;
		}
		&__img{
			left: unset;
			right: 0;
			top: mrem(70);
			height: mrem(197);
			width: mrem(227);
		}
	}
}
.page-404 {
    padding-bottom: rem(120);
    &__text {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: rem(67);
        width: 70%;
        margin: 0 auto rem(120);
    }

    &__title {
        font-size: rem(56);
        line-height: 135%;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: $text;
    }

    &__image {
        display: block;
        
        object-fit: contain;
        object-position: center;
        
        width: 100%;
        height: 100%;
        
        max-width: 100%;
        max-height: 100%;

        &-wrap {
            margin: 0 auto;
            width: 41%;
        }
    }
}

@media (max-width: 1279px) {
    .page-404 {
        padding-bottom: mrem(70);

        &__title {
            font-size: mrem(30);
        }
    }
}

@media (max-width: 992px) {
    .page-404 {
        &__text {
            width: 90%;
        }
    }
}

@media (max-width: 576px) {
    .page-404 {
        &__text {
            width: 100%;
            grid-template-columns: 1fr;
            row-gap: 28px;
        }

        &__image-wrap {
            width: 90%;
        }
    }
}
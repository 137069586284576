.section-before_to_after{

	overflow: hidden;
	padding: rem(115) 0;
	&__title{
		margin-bottom: rem(70);
	}
	&__slide{
		height: rem(600) !important;
		padding: 0 rem(15);
		&-img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__arrows{
		display: flex;
		height: fit-content;
		align-items: baseline;
		padding: 0 rem(251);
		margin-top: 15%;
		--icon-width: #{rem(103)};
		--icon-height: #{rem(15)};
		--icon-color: #{$gold};
		.slider-arrow{
			&--right{
				margin: 0 0 0 rem(91);
			}
		}
	}
	&__slider-wrap{
		position: relative;
		max-width: calc(rem(1090) + 72px);
		.keen-slider{
			overflow: visible !important;
		}
	}
	&__dots{
		background: $main;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: rem(28) rem(49) rem(23);
		z-index: 1;
	}
	&__slider-container{
		position: relative;
	}
	&__wrap{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-right: rem(243);
		margin-top: -9.5%;
		position: relative;
	}
	&__info{
		width: rem(690);
		padding: rem(60) rem(73);
		background: $main;
		z-index: 1;
		&-title{
			margin-bottom: rem(11);
			font-size: rem(36);
			line-height: 1.35;
			color: $gold;
			text-transform: uppercase;
		}
		&-type, &-desc{
			color: #fff;
			font-size: rem(19);
		}
		&-type{
			margin-bottom: rem(53);
		}
		&-desc{
			letter-spacing: 0.05em;
			span{
				text-transform: uppercase;
			}
		}
		&-btn{
			margin-top: rem(81);
			padding-right: rem(54);
			padding-left: rem(20);
			font-weight: 400;
			font-size: rem(24);
			line-height: rem(33);
		}
	}

	&__gallery {
		width: 100%;
		height: 100%;
	}

	&__swiper-main {
		overflow: visible !important;
	}

	&__swiper-mini {
		height: rem(600) !important;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.project {
	&-category {
		&__item {
			display: flex;
			flex-direction: column;
			align-items: end;
			position: relative;
			&-wrap {
				width: 83.2%;
				min-height: rem(293);
				background-repeat: no-repeat;
				background-size: cover;
				background-color: $green;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: rem(55) rem(40) rem(46);
				transition: all $tr-time;

				&:hover {
					background-color: #1A4444;
				}
			}

			&-title {
				font-size: rem(36);
				line-height: 113.5%;
				text-align: center;
				text-transform: uppercase;
				color: #FFFFFF;
			}
		}

		&__subitem {
			width: 89.6%;
    		max-width: 100%;
			height: rem(311);
			background-repeat: no-repeat;
			background-size: cover;
			background-color: $green;
		}

		&__grid {
			display: grid;
			grid-template-columns: repeat(3,1fr);
			column-gap: rem(46);
			row-gap: rem(104);
			padding-bottom: rem(170);

			.category-item {
				&:nth-child(1) {
					z-index: 1;
				}

				&:nth-child(2) {
					position: absolute;
					top: rem(15);
					right: rem(15);
					z-index: 2;
				}

				&:nth-child(3) {
					position: absolute;
					top: rem(30);
					right: rem(30);
					z-index: 3;
				}

				&:nth-child(4) {
					position: absolute;
					top: rem(95);
					right: rem(95);
					z-index: 4;
				}
			}
		}

		&__link {
			margin: rem(45) auto 0;
			padding-left: rem(20);
			padding-right: rem(54);  
		}
	}

	&-list {
		display: grid;
		grid-template-columns: repeat(2,1fr);
		column-gap: rem(70);
		row-gap: rem(78);

		&__item {
			display: flex;
			flex-direction: column;
			position: relative;
		}

		&__wrap {
			padding-bottom: rem(230);
		}
	}

	&-item {
		$this:&;
		&__image {
			display: block;
			width: 91.5%;
			height: rem(492);
			min-height: rem(492);
			position: absolute;
			top: 19.8%;
			right: 8.2%;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				object-fit: contain;
				object-position: center;
				
				width: 100%;
				height: 100%;
				
				max-width: 100%;
				max-height: 100%;
				backdrop-filter: blur(20px);
				background: #b2b2b270;
			}
			
			&-wrap {
				position: relative;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: end;
				cursor: pointer;
				margin-bottom: rem(152);
				
				&:before {
					content: "";
					background-repeat: no-repeat;
					background-size: cover;
					background-color: $green;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					transition: all $tr-time;
					height: rem(472);
					width: 89.9%;
					transition: $tr-time;
				}

				@media (min-width: 1024px) {
					&:hover {
						#{$this}__link {
							opacity: 1;
						}
	
						&:before {
							background-color: #1A4444;   
						}
					}
				}
			}
		} 
		&__link {
			width: 91.5%;
			height: rem(492);
			min-height: rem(492);
			position: absolute;
			top: 19.8%;
			right: 8.2%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			background: rgba(0, 0, 0, 0.75);
			opacity: 0;
			transition: all $tr-time;
		}
		&__title {
			font-size: rem(19);
			line-height: 98.6%;
			color: $text;
			margin-bottom: rem(23);
		}
		&__square {
			font-size: rem(25);
			line-height: 132%;
			color: $text;
		}
		&__price {
			font-size: rem(32);
			line-height: 132%;
			color: $text;
		}

		&__btn {
			padding-left: rem(20);
			padding-right: rem(43);
		}
	}
}

.projects {
	&__list {
		overflow: visible !important;
	}

	&__item {
		width: 100%;
		box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		height: auto !important;

		&-title {
			font-size: rem(36);
			line-height: 135%;
			text-transform: uppercase;
			color: $text;
		}

		&-city {
			margin-top: rem(10);
			font-weight: 300;
			font-size: rem(24);
			line-height: 135%;
			color: rgba(18, 34, 35, 0.75);
		}

		&-text {
			font-family: 'Inter';
			font-weight: 300;
			font-size: rem(18);
			line-height: 135%;
			color: rgba(18, 34, 35, 0.65);
			margin-top: rem(41);
			padding-top: rem(51);
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 95%;
				height: 2px;
				background: rgba(18, 34, 35, 0.25);
				border-radius: 30px;
			}
		}

		&-btn {
			margin-top: rem(50);
			padding-right: rem(54);
			width: max-content;
		}
	}

	&__slider-mini {
		width: 100%;
		height: rem(323);
	}

	&__slide-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__dots {
		position: absolute;
		bottom: rem(20) !important;
		left: rem(20) !important;
		z-index: 1;

		.swiper-pagination-bullet {
			background: rgba(217, 217, 217, 0.5);
			border-radius: 30px;
			width: rem(40);
			height: rem(4);
			opacity: 1;

			&.swiper-pagination-bullet-active {
				background: #D9D9D9;
			}
		}
	}

	&__content {
		padding: rem(60) rem(61) rem(60) rem(68);
		flex: 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__btn {
		margin-top: rem(60);
		display: flex;
		justify-content: center;

		a {
			width: auto;
			padding-left: rem(60);
			padding-right: rem(59);
		}
	}

	&__arrows {
		display: flex;
		height: fit-content;
		align-items: baseline;
		justify-content: center;
		margin-top: mrem(50);
		--icon-width: #{rem(103)};
		--icon-height: #{rem(15)};
		--icon-color: #{$gold};
		.slider-arrow{
			&--right{
				margin: 0 0 0 rem(91);
			}
		}
	}
}

@media (min-width: 1280px) {
	.section-before_to_after .section-news__link-container {
		margin-top: rem(75);
	}

	.project-list__wrap {
		padding-bottom: mrem(70);
	}

	.projects__arrows {
		display: none;
	} 
}

@media (max-width: 1279px){
	.section-before_to_after{
		padding: mrem(31) 0;
		&__wrap{
			padding: 0;
			margin: 0;
			flex-direction: column;
		}
		&__slider-wrap{
			max-width: initial;
			width: auto;
			padding: 0;
			margin: 0 mrem(-15);
		}
		&__slide{
			height: mrem(200) !important;
			padding: 0 mrem(15);
		}
		&__info{
			position: static;
			width: 100%;
			padding: mrem(40) mrem(21) mrem(45);
			text-align: center;
			&-btn{
				display: flex;
				margin-top: mrem(49);
			}
			&-title{
				margin-bottom: mrem(15);
				font-size: mrem(26);
			}
			&-type{
				margin-bottom: mrem(22);
			}
			&-type, &-desc{
				font-size: mrem(13);
			}
			&-title, &-type, &-desc{
				padding: 0 mrem(14);
			}
		}
		&__arrows{
			margin-top: mrem(43);
			margin-bottom: 0;
			padding: 0;
			justify-content: center;
			order: 1;
			width: 100%;
			--icon-width: #{mrem(43)};
			--icon-height: #{mrem(15)};
			.slider-arrow{
				&--right{
					margin: 0 0 0 mrem(42);
				}				
			}
		}
		&__dots{
			display: none;
		}

		&__swiper-mini {
			height: mrem(200) !important;
		}

		&__title {
			margin-bottom: mrem(25);
		}
	}

	.project {
		&-category {
			&__grid {
				grid-template-columns: repeat(2, 1fr);
			}
	
			&__item {
				&-title {
					font-size: mrem(26);
				}
	
				&-wrap {
					padding: mrem(40) mrem(21);
				}
			}
		}

		&-item {
			&__image {
				height: mrem(220);
				min-height: mrem(220);
				&-wrap {
					margin-bottom: mrem(60);
					&:before {
						height: mrem(220);
					}
				}
			}

			&__link {
				height: mrem(220);
				min-height: mrem(220);
			}
		}

		&-list__wrap {
			padding-bottom: mrem(35); 
		}
	}

	.projects {
		&__content {
			padding: mrem(15) mrem(21) mrem(45);
		}

		&__slider-mini {
			height: mrem(220);
		}
	}
}
@media (max-width: 767px){
	.project-category {
		&__grid {
			grid-template-columns: 1fr;
		}
	}

	.project {
		&-list {
			grid-template-columns: 1fr;
		}
	}
}

@media (max-width: 576px){
	.project-category {
		&__grid {
			row-gap: 50px;
		}

		&__subitem {
			display: none;
		}

		&__item {
			&-wrap {
				position: relative !important;
				top: 0 !important;
				right: 0 !important;
				width: 100%;
				height: auto;
			}
		}
	}

	.project {
		&-item {
			&__image {
				position: static;
				width: 100%;
				&-wrap {
					margin-bottom: 15px;
					&:before {
						display: none;
					}
				}
			}
		}
	}

	.projects {
		&__item-btn {
			width: 100%;
		}

		&__btn a {
			width: 100%;
		}
	}
}
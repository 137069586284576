.main-menu{
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: rem(100) 0 0;
	z-index: 9;
	color: #EFC99B;
	background: #122223;
	transition-duration: $tr-time;
	transition-property: transform, opacity;
	&:not(.opened){
		pointer-events: none;
		opacity: 0;
		transform: translateY(-10%);
	}
	&__footer{
		padding: 4.5vh 0 7vh;
		border-top: 1px solid rgba(255, 255, 255, 0.25);
		&-container{
			display: flex;
		}
		&-nav{
			margin-left: auto;
			flex: 0 0 46.5%;
			.menu-link{
				&:not(:last-child){
					margin-right: rem(65);
				}
			}
		}
	}
	&__top{
		padding: 8vh 0;
		flex-grow: 1;
		overflow: auto;
		&-container{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
	}
	&__contact{
		font-size: rem(36);
		color: $orange;
		transition: $tr-time;

		@media (min-width: 1024px) {
			&:hover {
				color: #fff;
			}
		}
	}
	&__tel{
		margin-right: rem(64);
	}
	&__btn{
		padding: rem(43) rem(13);
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%) rotate(180deg);
		font-size: rem(24);
		color: $white;
		background: $orange;
		span{
			display: block;
			writing-mode: vertical-lr;
		}
	}
	&__left-menu{
		display: grid;
		grid-template-columns: max-content max-content;
		gap: rem(35) rem(64);
		flex: 0 0 48%;
		.menu-header{
			flex: 0 0 100%;
			grid-column: span 2;
			margin-bottom: rem(19);
		}
	}
	&__right-menu{
		flex: 0 0 46.5%;
		padding: 1vh 0 0;
		.menu-header{
			display: block;
			&:not(:last-child){
				margin-bottom: rem(57);
			}
		}
	}
}

.menu-link{
	font-size: rem(36);
	line-height: 1.1;
	&:hover{
		color: $orange;
	}
}
.menu-header{
	font-size: rem(87);
	line-height: 1;
	&:hover{
		color: $orange;
	}
}

@media (min-width: 1280px){
	.main-menu{
		&__right-menu{
			&-mob{
				display: none;
			}
		}
	}
}

@media (max-width: 1279px){
	.main-menu{
		padding: mrem(55) 0 0;
		&__btn{
			top: unset;
			bottom: 0;
			right: 50%;
			font-size: mrem(14);
			padding: mrem(5) mrem(30);
			transform: translateX(50%);
			span{
				writing-mode: horizontal-tb;
				white-space: nowrap;
			}
		}
		&__footer{
			padding: mrem(35) 0 mrem(65);
			&-container{
				flex-direction: column;
				align-items: center;
			}
			&-nav{
				display: none;
			}
		}
		&__top{
			padding: mrem(35) 0;
			&-container{
				flex-direction: column;
				align-items: center;
			}
		}
		&__left-menu{
			grid-template-columns: 1fr;
			gap: mrem(10);
			margin-bottom: mrem(16);
			.menu-header{
				margin: 0;
				grid-column: auto;
			}
			.menu-link{
				text-align: center;
			}
		}
		&__right-menu{
			padding: 0;
			.menu-header{
				text-align: center;
				&:not(:last-child){
					margin-bottom: mrem(7);
				}
			}
		}
		&__tel{
			margin: 0 0 rem(10);
		}
		&__contact{
			font-weight: 500;
			font-size: mrem(14);
		}
	}
	.menu-header{
		font-size: mrem(32);
		line-height: normal;
	}
	.menu-link{
		font-size: mrem(14);
	}
}
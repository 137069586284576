.section-color {
    padding: rem(115) 0 rem(134);
    overflow-x: clip;

    .section-title {
        width: 59%;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: rem(28);
        }
    }

    &__button {
        padding: rem(18) rem(68) rem(15);
        background: transparent;
        color: $text;
        border: 1px solid $text;
        margin-bottom: rem(13);

        @media (min-width: 1024px) {
            &:hover {
                background: $orange;
                color: $white;
                border-color: $orange;
            }
        }
    }

    &__slider-container {
        width: 100%;
        // display: grid;
        // grid-template-columns: 1fr auto;
        position: relative;
        padding-top: rem(70);
    }

    .slider-color__wrap {
        overflow-x: clip;
        width: 100%;
        margin-left: rem(-75);
        width: calc(100% + rem(75));
        padding-left: rem(75);
    }

    &__form {
        &-section {
            background: $main;
            width: rem(368);
            position: absolute;
            top: 0;
            right: 0;
        }

        &-text {
            padding: 0 rem(50) rem(50);
            margin-top: -13px;
        }

        &-title {
            font-size: rem(33);
            line-height: 125.5%;
            color: #FFFFFF;
        }

        &-subtitle {
            font-size: rem(19);
            line-height: rem(23);
            color: #FFFFFF;
            text-align: end;
        }

        &-image {
            margin-left: auto;
            margin-right: rem(-20);
            margin-top: rem(-30);
            width: rem(220);
            height: rem(237);
            user-select: none;
        }
    }

    .keen-slider {
        overflow: visible !important;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: visible !important;
        $this: &;

        &-image {
            width: rem(98);
            height: rem(98);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        &-full {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            $top: rem(73);
            bottom: calc(100% + #{$top});
            width: rem(257);
            height: rem(257);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            transition: .3s;

            img {
                width: rem(235);
                height: rem(235);
                object-fit: cover;
            }

            &:after {
                content: "";
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                position: absolute;
                top: 100%;
                left: 50%;
                width: rem(61);
                height: rem(53);
                background: #FFFFFF;
                transform: rotate(180deg) translateX(50%);
            }
        }

        &-title {
            margin-top: rem(21);
            text-align: center;
            font-weight: 300;
            font-size: rem(19);
            line-height: 148%;
        }

        @media (min-width: 1024px) {
            &:hover {
                #{$this}-full {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__arrows {
        padding-left: rem(108);
        display: flex;
        align-items: center;
        margin-top: rem(90);
        width: 100%;
        position: relative;
        --icon-width: #{rem(103)};
        --icon-height: #{rem(15)};
        --icon-color: #{$gold};

        .slider-arrow {
            &--right {
                margin: 0 0 0 rem(91);
            }
        }
    }

    .warning_text {
        position: absolute;
        top: 140%;
        display: flex;
        gap: 5px;
        left: 4%;
            a{
                color: #EBA049;
                &:hover{
                    text-decoration: underline 1px solid #EBA049;
                }
            }   
    }

}


#slider-color {
    width: 76%;
    position: relative;

    .gray_border {
        position: absolute;
        width: 65%;
        height: 1%;
        background: rgba(18, 34, 35, 0.5019607843);
        top: 130%;
        left: 4%;
    }


}

@media (max-width: 1279px) {
    .section-color {
        .warning_text {
            position: absolute;
            top: 131% !important;
        }

        padding: mrem(35) 0;

        &__slider-container {
            margin-left: 0;
            width: 100%;
        }

        &__button {
            padding-left: 30px;
            padding-right: 30px;
        }

        &__arrows {
            margin-top: mrem(50);

            .slider-arrow {
                &--right {
                    margin: 0 0 0 mrem(42);
                }
            }
        }
    }
}


@media (max-width: 992px) {
    .gray_border {
        display: none;
    }

    .section-color {
        .warning_text {
            position: absolute;
            top: unset !important;
            bottom: rem(25) !important;
        }
    }

    .section-color {

        &__form-section {
            position: static;
            margin: mrem(50) auto 0;
        }

        &__slider-container {
            grid-template-columns: 1fr;
            padding-top: 0;
        }

        &__arrows {
            justify-content: center;
            margin-bottom: 0;
            padding-left: 0;

            &:after {
                display: none;
            }
        }
    }

    #slider-color {
        width: calc(100vw - 42px);
    }
}

@media (max-width: 767px) {
    .section-color {
        .warning_text {
            position: absolute;
            top: unset !important;
            bottom: -381px !important;
            flex-direction: column;
        }

        padding-bottom: 5.5rem;

        .section-title {
            width: 100%;
            margin-bottom: 20px;
        }

        .section-title__header {
            flex-wrap: wrap;
            align-items: baseline;
        }
    }
}
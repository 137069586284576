.section-surface {
    padding: rem(115) 0;
    overflow-x: clip;

    @media (max-width: 1279px) {
        padding: mrem(35) 0;
    }

    &__title {
        margin-bottom: rem(90);
    }

    .keen-slider:not([data-keen-slider-disabled]) {
        overflow: visible;
    }

    &__item {
        &-image-wrap {
            aspect-ratio: 0.97;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        $this: &;

        &-text {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            
            font-size: rem(28);
            line-height: 135%;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            text-align: center;
            color: #FFFFFF;

            width: 100%;
            height: 100%;
            padding: 0 rem(32);

            background: rgba(0, 0, 0, 0.5);
            transition: $tr-time;
            opacity: 0;

            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }

        
    }

    &__arrows {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem(75);
        --icon-width: #{rem(128)};
		--icon-height: #{rem(25)};
		--icon-color: #{$gold};

        @media (max-width: 1279px) {
            margin-top: mrem(50);
        }

		.slider-arrow{
			&--right{
				margin: 0 0 0 rem(91);

                @media (max-width: 1279px) {
                    margin: 0 0 0 mrem(42);
                }
			}
		}
    }
}

@media (max-width: 1279px){
    .section-surface {
        &__title {
            margin-bottom: mrem(25);
        }
    }   
}
.section-gallery-files-content{
  padding: rem(115) 0;
  .section-title{
    font-size: rem(87);
  }
  .container{
    display: flex;
    gap: rem(100);
  }
  .text-col{
    flex: 0 0 45%;
    .calculate-form__filters {
      margin-bottom: 0;
    }
    .files {
      margin-top: rem(90);
      display: flex;
      flex-direction: column;
      gap: rem(16);
      max-width: rem(560);
      width: 100%;
    }
  }
  .content-text{
    margin-top: rem(90);
  }
  .file-download{
    // margin-top: rem(90);
  }
  .content-col{
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    position: relative;
    // padding: rem(55) rem(151) 0 rem(77);
  }
  .gallery-files-img{
    width: 100%;
    max-width: rem(911);
    aspect-ratio: 911 / 706;
    height: auto;
    object-fit: contain;
  }
}

@media (min-width: 1280px){
  .section-gallery-files-content{
    .file-download{
      padding-left: rem(68);
      padding-right: rem(68);
    }
  }
}

@media (max-width: 1279px){
  .section-gallery-files-content{
    padding: mrem(35) 0;
    .section-title{
      width: 72%;
      font-size: mrem(26);
    }
    .content-text{
      margin-top: mrem(25);
    }
    .gallery-files-img{
      &.mobile{
        margin-top: mrem(25);
      }
    }
    .files {
      margin-top: mrem(25) !important;
    }
    .file-download{
      // margin-top: mrem(25);
    }
    .text-col{
      flex: 0 0 40%;
    }
    .content-col{
      padding: rem(40) rem(100) 0 rem(55);
    }
  }
}

@media (min-width: 992px){
  .section-gallery-files-content{
    .gallery-files-img{
      &.mobile{
        display: none;
      }
    }
  }
}

@media (max-width: 991px){
  .section-gallery-files-content{
    .container{
      display: block;
    }
    .content-col{
      display: none;
    }
    .gallery-files-img{
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    .section-title{
      width: 65%;
    }
  }
}

@media (max-width: 767px) {
  .files {
    max-width: 100% !important;
  }
}

@media (max-width: 575px){
  .section-gallery-files-content{
    .gallery-files-img{
      width: 100%;
    }
  }
}
.container {
  width: 100%;
  max-width: calc(92.2vw + 42px);
  margin: 0 auto;
  padding: 0 21px;

  &-news {
    max-width: calc(68vw + 42px);
    width: 100%;
    margin: 0 auto;
  }
}
.hidden {
  display: none !important;
}
.overflow {
  &-auto {
    overflow: auto;
  }
  &-hidden {
    overflow: hidden;
  }
}
.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.collapse {
  overflow: hidden;
  transition: height $tr-time * 2;
  &.is-collapsed {
    height: 0;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(64);
  min-height: rem(64);
  // padding: 0 rem(54) 0 rem(25);
  padding: 0 rem(25) 0 rem(25);
  position: relative;
  color: #fff;
  font-size: rem(22);
  line-height: 1;
  background: $orange;
  transition-property: border-color, color, background-color;
  transition-duration: $tr-time;
  cursor: pointer;
  text-align: center;

  $this: &;
  &--transparent {
    border: 1px solid #fff;
    background: transparent;

    @media (min-width: 1024px) {
      &:hover {
        color: #fff;
        background: $orange;
        border-color: $orange;
      }
    }
  }

  &--transparent-dark {
    color: $text;
    border: 1px solid $text;
    background: transparent;
    #{$this}__arrow {
      fill: $text;
    }

    &.active {
      color: #fff;
      background: $orange;
      border-color: $orange;
      #{$this}__arrow {
        fill: #fff;
      }
    }

    @media (min-width: 1024px) {
      &:hover {
        color: #fff;
        background: $orange;
        border-color: $orange;
        #{$this}__arrow {
          fill: #fff;
        }
      }
    }
  }

  &--white {
    color: $text;
    border: 1px solid #fff;
    background: #fff;
    #{$this}__arrow {
      fill: $text;
    }

    @media (min-width: 1024px) {
      &:hover {
        color: #fff;
        background: transparent;
        border-color: #fff;
        #{$this}__arrow {
          fill: #fff;
        }
      }
    }
  }

  &--hover-white {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
    #{$this}__arrow {
      fill: #fff;
    }

    @media (min-width: 1024px) {
      &:hover {
        background: #fff;
        border-color: #fff;
        color: $text;
        #{$this}__arrow {
          fill: $text;
        }
      }
    }
  }

  &--dark {
    border: 1px solid #fff;
    background: transparent;
    #{$this}__arrow {
      fill: #fff;
    }

    @media (min-width: 1024px) {
      &:hover {
        color: #fff;
        background: $orange;
        border-color: $orange;
        #{$this}__arrow {
          fill: #fff;
        }
      }
    }
  }

  &--filled {
    border: 1px solid $orange;
    background: $orange;

    @media (min-width: 1024px) {
      &:hover {
        color: $text;
        border: 1px solid $text;
        background: transparent;
        #{$this}__arrow {
          fill: $text;
        }
      }
    }
  }

  &--orange {
    border: 1px solid $orange;
    background: $orange;

    @media (min-width: 1024px) {
      &:hover {
        background: transparent;
        border: 1px solid #fff;
      }
    }
  }

  &--black {
    border: 1px solid $text;
    background: $text;

    @media (min-width: 1024px) {
      &:hover {
        background: $orange;
        border: 1px solid $orange;
      }
    }
  }

  &--main {
    border: 1px solid $main;
    background: $main;

    @media (min-width: 1024px) {
      &:hover {
        background: $orange;
        border: 1px solid $orange;
        color: #fff;
      }
    }
  }

  &--inline {
    display: inline-flex;
  }
  &:disabled {
    color: transparent;
    pointer-events: none;
    transition-duration: 0s;
    #{$this}__arrow {
      opacity: 0;
    }
  }
  &__arrow {
    width: rem(21);
    height: rem(11);
    margin-right: rem(20);
    fill: #fff;

    &--bottom {
      transform: rotate(90deg);
    }
  }
}

.inter {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.section-title {
  font-size: rem(100);
  line-height: 1;
  text-transform: uppercase;

  &.no-uppercase {
    text-transform: none;
  }

  &--87 {
    font-size: rem(87);
    line-height: 98.6%;
    margin-bottom: rem(30);
  }

  &--80 {
    font-size: rem(80);
    line-height: rem(109);
    text-transform: uppercase;
  }
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.section-subtitle {
  margin-bottom: rem(23);
  width: 85.2%;
}

.page-description {
  width: 85.2%;
}

.page-title {
  font-weight: 400;
  font-size: rem(100);
  line-height: rem(133);
  text-transform: uppercase;
  color: #122223;
  margin-bottom: rem(71);

  &.align-center {
    text-align: center;
  }

  &--project {
    margin-bottom: rem(56);
  }
}

.link-cover {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.font {
  &-22 {
    font-weight: 400;
    font-size: rem(22);
    line-height: 128.6%;
    color: $text;
  }
}

.m {
  &-90 {
    margin-bottom: rem(90);
  }

  &-70 {
    margin-bottom: rem(70);
  }

  &-40 {
    margin-bottom: rem(40);
  }

  &-38 {
    margin-bottom: rem(38);
  }
}

@media (max-width: 1279px) {
  .btn {
    height: mrem(48);
  }
  .section-title,
  .page-title {
    font-size: mrem(36);
    line-height: 1;
  }
  .container {
    max-width: 100%;
  }
  .h3 {
    font-size: mrem(21);
  }

  .section-title--87 {
    font-size: mrem(26);
    line-height: 1;
  }

  .section-title--80 {
    font-size: mrem(26);
    line-height: 1;
  }

  .m {
    &-90 {
      margin-bottom: mrem(50);
    }

    &-70 {
      margin-bottom: mrem(25);
    }

    &-40 {
      margin-bottom: mrem(25);
    }

    &-38 {
      margin-bottom: mrem(25);
    }
  }
}

.preloader-circles {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: wheat url("../../static/images/preloader.svg") no-repeat center center;
}

.section-render {
    padding: rem(122) 0;
    overflow: hidden;

    .slider-render {
        height: rem(1080);
        max-height: 100vh;

        &__slide {
            height: 100%;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &-slider {
                height: 100%;
            }
        }

        &--thumbnail {
            position: absolute;
            top: 0%;
            left: rem(75);
            height: 100%;
            padding: rem(70) 0;
            width: rem(106);

            .swiper-render--thumbnail {
                position: absolute;
                top: 10%;
                height: calc(93% - #{rem(34)});
            }
        }

        &__thumbnail-slide {
            width: rem(106);
            height: rem(106) !important;
            border-radius: 100%;
            border: 5px solid #FFFFFF;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.swiper-slide-thumb-active {
                border-color: #EBA149;
            }
        }

        &__slide-slider--thumbnail {
            position: absolute;
            bottom: rem(34);
            left: 21px;
            overflow: visible;
            overflow-x: clip;
            width: calc(100% - 21px);

            .swiper-wrapper {
                justify-content: center;
            }

            .swiper-slide {
                width: fit-content;
                height: rem(200);
                display: flex;
                align-items: flex-end;
                padding: 0 15px;
                cursor: pointer;
                //transition: $tr-time linear;
                transition: .1s linear;
                &__image-wrap {
                    width: rem(40);
                    padding-top: 91%;
                    border: 2px solid #FFFFFF;
                    cursor: pointer;
                    position: relative;
                    transition: .2s linear;
                    .preloader-circles{
                        background-size: 20px;
                        font-size: 20px;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    pointer-events: none;
                }
                
                &__wrap {
                    transition: .1s linear;
                    pointer-events: none;
                }

                &.swiper-slide-thumb-active {
                    border-color: #EBA149;
                }

                &.slide-hover {
                    .swiper-slide__wrap {
                        margin: 0 rem(30);
                    }
                    .swiper-slide__image-wrap {
                        //width: rem(110);
                        //transform: translate(0, -78px);
                        transform: translate(0, -78px) scale(2.5);
                     }
                }

                &.slide-hover-2 {
                    .swiper-slide__wrap {
                        margin: 0 rem(26);
                    }
                    .swiper-slide__image-wrap {
                        //width: rem(100);
                        // transform: translate(0, -60px);
                        transform: translate(0, -60px) scale(2.25);
                     }
                }

                &.slide-hover-3 {
                    .swiper-slide__wrap {
                        margin: 0 rem(20);
                    }
                    .swiper-slide__image-wrap {
                        //width: rem(90);
                        // transform: translate(0, -30px);
                        transform: translate(0, -30px) scale(2);
                     }
                }

                &.slide-hover-4 {
                    .swiper-slide__wrap {
                        margin: 0 rem(6);
                    }
                    .swiper-slide__image-wrap {
                        //width: rem(60); 
                        //transform: translate(0, -14px);
                        transform: translate(0, -14px) scale(1.25);
                     }
                }
            }
        }

        &__thumbnail {
            &-arrows {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .slider-arrow {
                    background: #1E372A;
                    z-index: 3;

                    &--left {
                        transform: rotate(90deg) translateX(-10px);
                        padding: rem(17) rem(11) rem(17) rem(11);
                        border-radius: 0px 44px 44px 0px;
                    }

                    &--right {
                        padding: rem(17) rem(11) rem(17) rem(11);
                        transform: rotate(90deg) translateX(10px);
                        border-radius: 44px 0px 0px 44px;
                    }

                    svg {
                        fill: #fff;
                        width: rem(25);
                        height: rem(25);
                    }

                    &.swiper-button-disabled {
                        opacity: 0.8;
                    }

                    &:not(.swiper-button-disabled):hover {
                        svg {
                            fill: $orange
                        }
                    }
                }
            }
        }
    }

    &__wrap {
        position: relative;
        overflow: hidden;
        z-index: 10;
    }
}

@media (max-width: 1279px) {
    .section-render {
        padding: mrem(35) 0;
        .slider-render--thumbnail {
            left: 21px;
        }

        .slider-render__thumbnail-slide {
            width: mrem(70) !important;
            height: mrem(70) !important;  
        }

        .slider-render__slide-slider--thumbnail .swiper-slide {
            // width: mrem(70);
            // height: mrem(70);

            .swiper-slide__image-wrap {
                width: mrem(60);
                height: mrem(50);
            }
        }

        .slider-render__slide-slider--thumbnail {
            height: auto;
            left: mrem(110);
            width: calc(100% - #{rem(110)});
        }

        .slider-render {
            height: mrem(500);
        }
    }
}

@media (max-width: 767px) {
    .section-render {
        .slider-render__slide-slider--thumbnail .swiper-wrapper {
            justify-content: flex-start;
        }
        
        .slider-render__slide-slider--thumbnail {
            left: 10px;
            width: calc(100% - 10px);
        }

        .slider-render--thumbnail {
            position: static;
            margin-top: mrem(35);
            margin-left: 0;
            padding: 0; 
            width: 100%;

            .swiper-render--thumbnail {
                position: static;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        .slider-render__thumbnail-arrows {
            position: static;
            
            flex-direction: row;

            .slider-arrow--left {
                transform: none;
            }

            .slider-arrow--right {
                transform: none;
            }
        }
    }
}


